<template>
  <div class="s-image-selector">
    <slot name="trigger" :open-file-selector="openFileSelector">
      <button @click="openFileSelector" v-if="!image">Add Photo</button>
    </slot>
    <input class="s-file-input" type="file" name="image" accept="image/*" ref="input"
      @change="loadImage($event.target.files[0])" />
    <transition name="fade">
      <div class="s-cropper-popup" v-if="popupOpened">
        <div class="s-cropper-popup-inner">
          <VueCropper class="sr-only" :src="image" :aspect-ratio="aspectRatio" :initialAspectRatio="aspectRatio"
            ref="cropper" />
          <div v-if="!liffStyle" class="mb-3">
            <button class="btn btn-outline-danger btn-sm s-cancel-btn" type="button" @click="closePopup">取消</button>
            <button class="btn btn-success btn-sm" type="button" @click="cropImage">確定</button>
          </div>
          <div v-else class="d-flex justify-content-between mb-3">
            <SharedButton variant="outline-dark" class="s-btn-outline-primary" @click="closePopup" block>
              取消
            </SharedButton>
            <SharedButton variant="primary" class="s-btn-bg-primary ml-2" @click="cropImage" block>
              確定
            </SharedButton>
          </div>
          <slot name="content">
            </slot>
          <div>
            <img :id="randomId" :src="image" />
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import imageApi from "@/apis/image";
import 'croppr/dist/croppr.css'
import 'cropperjs/dist/cropper.css'
import Croppr from 'croppr'
import VueCropper from 'vue-cropperjs'
import { v4 as uuidv4 } from 'uuid'
import SharedButton from "@/components/Page/Liff/Shared/Button";

Croppr.prototype.attachOverlayEvents = function () { }

export default {
  components: { VueCropper, SharedButton },
  props: {
    aspectRatio: {
      default: 1 / 1
    },
    liffStyle: {
      default: false
    },
    croppedCanvasOptions: {
      default: () => ({ width: 1024, height: 1024 })
    }
  },
  data: () => ({
    popupOpened: false,
    randomId: null,
    croppr: null,
    image: null,
    croppedImage: null,
  }),
  methods: {
    openFileSelector() {
      this.$refs.input.click()
    },
    async loadImage(file) {
      this.popupOpened = true
      this.randomId = `image-${uuidv4()}`

      await this.$nextTick()

      const reader = new FileReader()

      reader.onload = (event) => {
        this.image = event.target.result
        this.$refs.input.value = null
        this.$refs.cropper.replace(this.image)
        this.$nextTick(this.initCroppr)
      }

      reader.readAsDataURL(file)
    },
    initCroppr() {

      // cropper 跟 vue-cropperjs 的比例設定剛好顛倒
      const options = {
        aspectRatio: 1 / this.aspectRatio,
        startSize: [100, 100, '%'],
        minSize: [30, 30, 'px'],
      }

      this.croppr = new Croppr(`#${this.randomId}`, options)
    },
    closePopup() {
      this.image = null
      this.croppr = null
      this.popupOpened = false
    },
    cropImage() {
      this.croppedImage = this.$refs.cropper
        .setData(this.croppr.getValue())
        .getCroppedCanvas({
          width: this.croppedCanvasOptions.width,
          height: this.croppedCanvasOptions.height
        })
        .toDataURL('image/jpeg', 0.5)

      this.$emit('input', this.croppedImage)

      this.closePopup()
    },
    async uploadImage() {
      let response = await imageApi.uploadByBase64(this.croppedImage);
      return response.data.data.image.url
    },
  },
}
</script>

<style scoped>
.fade-enter-active,
.fade-leave-active {
  transition: opacity .15s ease-in-out;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
}

.s-file-input {
  visibility: hidden;
  overflow: hidden;
  width: 0;
  height: 0;
}

.s-cropper-popup {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 10000;
  background-color: rgb(33 33 33 / 33%)
}

.s-cropper-popup-inner {
  width: calc(100% - 2rem);
  max-width: 480px;
  padding: 1rem;
  text-align: center;
  box-shadow: 0 0 0 0 rgb(90 113 208 / 11%), 0 4px 16px 0 rgb(167 175 183 / 33%);
  border: solid 1px #dde4eb;
  background-color: white;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
}

.s-cancel-btn {
  margin-right: 1rem;
}
</style>
